<template>
  <div class="d-flex justify-center align-center">
    <v-container>
      <v-row>
        <v-col cols="12">

          <v-card flat class="d-flex flex-column justify-center align-center pa-5 mt-0">
            <v-card-text class="d-flex justify-center display-1 font-weight-light mb-5">
              Вы изучили все страницы
            </v-card-text>
            <v-icon
            color="light-green"
            size="50"
            class="mb-5"
            >
              mdi-check-circle
            </v-icon>
            <v-card-title class="display-1 font-weight-light text-center">Итоговая оценка: {{ totalGrade() }}</v-card-title>
            <!-- <v-card-title class="title font-weight-light text-center">для повышения оценки пройдите те</v-card-title> -->
          </v-card>

        </v-col>
        <v-col cols="12">
          <v-card-text class="title font-weight-light">Результаты:</v-card-text>
        </v-col>
        <v-col cols="12">
          <v-card class="pa-5">
            <Stat></Stat>
          </v-card>
        </v-col>
        <v-col
          cols="6">
            <v-card class="d-flex flex-column pa-5">
              <v-icon
                color="red"
                class="mb-5"
                size="50"
                >
                  mdi-school-outline
                </v-icon>
              <v-btn
              to="/"
              >
                Главная страница
              </v-btn>
            </v-card>
          </v-col>
          <v-col
          cols="6">
            <v-card class="d-flex flex-column pa-5">
              <v-icon
                color="red"
                class="mb-5"
                size="50"
                >
                  mdi-web
                </v-icon>
              <v-btn
              href="http://umcmrg.ru/"
              >
                Сайт НОЦ
              </v-btn>
            </v-card>
          </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Stat from '@/components/Stat.vue'
export default {
  components: {
    Stat
  },
  computed: {
    ...mapGetters([
      'allQuestionsQuiz1',
      'allQuestionsQuiz2',
      'allQuestionsQuiz3',
      'allQuestionsQuiz4',
      'allQuestionsQuiz5',
      'allQuestionsQuiz6'
    ])
  },
  data: () => ({
    result: ''
  }),
  methods: {
    getResQuiz1: function () {
      return Number(localStorage.resultQuiz1)
    },
    getResQuiz2: function () {
      return Number(localStorage.resultQuiz2)
    },
    getResQuiz3: function () {
      return Number(localStorage.resultQuiz3)
    },
    getResQuiz4: function () {
      return Number(localStorage.resultQuiz4)
    },
    getResQuiz5: function () {
      return Number(localStorage.resultQuiz5)
    },
    getResQuiz6: function () {
      return Number(localStorage.resultQuiz6)
    },
    culcResults: function () {
      return this.getResQuiz1() + this.getResQuiz2() + this.getResQuiz3() + this.getResQuiz4() + this.getResQuiz5() + this.getResQuiz6()
    },
    culcQuestions: function () {
      return Number(this.allQuestionsQuiz1.length) + Number(this.allQuestionsQuiz2.length) + Number(this.allQuestionsQuiz3.length) + Number(this.allQuestionsQuiz4.length) + Number(this.allQuestionsQuiz5.length) + Number(this.allQuestionsQuiz6.length)
    },
    getTotalGrade: function () {
      return this.culcResults() * 100 / this.culcQuestions()
    },
    totalGrade: function () {
      if (this.getTotalGrade() >= 70 && this.getTotalGrade() < 85) {
        return 3
      } else if (this.getTotalGrade() >= 85 && this.getTotalGrade() < 95) {
        return 4
      } else if (this.getTotalGrade() >= 95) {
        return 5
      } else {
        return 'Необходимо завершить все тесты'
      }
    }
  },
  mounted () {
    this.getResQuiz1()
  }
}
</script>
