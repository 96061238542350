<template>
  <div>
    <v-tabs grow color="red" v-model="resultTab">
      <v-tab>Раздел 1</v-tab>
      <v-tab>Раздел 2</v-tab>
      <v-tab>Раздел 3</v-tab>
    </v-tabs>
    <v-tabs-items v-model="resultTab">
      <v-tab-item>
        <v-card-text class="title pt-10 pb-10">
          Законодательная база в области пожарной безопасности
        </v-card-text>
        <v-divider></v-divider>
        <v-card flat class="d-flex">
          <v-row>
            <v-col cols="9">
              <v-card-text class="title font-weight-light">
                <template v-if="getResQuiz1() > 0">
                  <v-icon color="green" class="mr-2">mdi-check</v-icon>
                </template>
                <template v-else>
                  <v-icon color="red" class="mr-2">mdi-close</v-icon>
                </template>
                Тестирование по теме: "Законодательная база в области пожарной безопасности"
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text class="title font-weight-light text-center">
                Правильных ответов:<br> {{ getResQuiz1() }} из {{ allQuestionsQuiz1.length }}
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card-text class="title pt-10 pb-10">
          Организационные мероприятия по обеспечению ПБ в зданиях и помещениях с массовым пребыванием людей
        </v-card-text>
        <v-divider></v-divider>
        <v-card flat class="d-flex">
          <v-row>
            <v-col cols="9">
              <v-card-text class="title font-weight-light">
                <template v-if="getResQuiz2() > 0">
                  <v-icon color="green" class="mr-2">mdi-check</v-icon>
                </template>
                <template v-else>
                  <v-icon color="red" class="mr-2">mdi-close</v-icon>
                </template>
                Тестирование по теме: "Требования по обеспечению ПБ в зданиях и помещениях с массовым пребыванием людей"
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <template v-if="getResQuiz3() > 0">
                  <v-icon color="green" class="mr-2">mdi-check</v-icon>
                </template>
                <template v-else>
                  <v-icon color="red" class="mr-2">mdi-close</v-icon>
                </template>
                Тестирование по теме: "Меры ПБ в зданиях и помещениях с массовым скоплением людей"
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text class="title font-weight-light text-center">
                Правильных ответов:<br> {{ getResQuiz2() }} из {{ allQuestionsQuiz2.length }}
              </v-card-text>
              <v-card-text class="title font-weight-light text-center">
                Правильных ответов:<br> {{ getResQuiz3() }} из {{ allQuestionsQuiz3.length }}
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card-text class="title pt-10 pb-10">
          Действия работников при пожарах в учреждениях
        </v-card-text>
        <v-divider></v-divider>
        <v-card flat class="d-flex">
          <v-row>
            <v-col cols="9">
              <v-card-text class="title font-weight-light">
                <template v-if="getResQuiz4() > 0">
                  <v-icon color="green" class="mr-2">mdi-check</v-icon>
                </template>
                <template v-else>
                  <v-icon color="red" class="mr-2">mdi-close</v-icon>
                </template>
                Тестирование по теме: "Первичные средства пожаротушения, автоматические установки пожарной сигнализации и пожаротушения"
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <template v-if="getResQuiz5() > 0">
                  <v-icon color="green" class="mr-2">mdi-check</v-icon>
                </template>
                <template v-else>
                  <v-icon color="red" class="mr-2">mdi-close</v-icon>
                </template>
                Тестирование по теме: "Действия сотрудников и руководителя при возникновении пожара, вызов пожарной охраны"
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <template v-if="getResQuiz6() > 0">
                  <v-icon color="green" class="mr-2">mdi-check</v-icon>
                </template>
                <template v-else>
                  <v-icon color="red" class="mr-2">mdi-close</v-icon>
                </template>
                Тестирование по теме: "Оказание первой помощи пострадавшим при пожаре"
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text class="title font-weight-light text-center">
                Правильных ответов:<br> {{ getResQuiz4() }} из {{ allQuestionsQuiz4.length }}
              </v-card-text>
              <v-card-text class="title font-weight-light text-center">
                Правильных ответов:<br> {{ getResQuiz5() }} из {{ allQuestionsQuiz5.length }}
              </v-card-text>
              <v-card-text class="title font-weight-light text-center">
                Правильных ответов:<br> {{ getResQuiz6() }} из {{ allQuestionsQuiz6.length }}
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    resultTab: null
  }),
  computed: {
    ...mapGetters([
      'allQuestionsQuiz1',
      'allQuestionsQuiz2',
      'allQuestionsQuiz3',
      'allQuestionsQuiz4',
      'allQuestionsQuiz5',
      'allQuestionsQuiz6'
    ])
  },
  methods: {
    getResQuiz1: function () {
      return localStorage.resultQuiz1 ? localStorage.resultQuiz1 : 0
    },
    getResQuiz2: function () {
      return localStorage.resultQuiz2 ? localStorage.resultQuiz2 : 0
    },
    getResQuiz3: function () {
      return localStorage.resultQuiz3 ? localStorage.resultQuiz3 : 0
    },
    getResQuiz4: function () {
      return localStorage.resultQuiz4 ? localStorage.resultQuiz4 : 0
    },
    getResQuiz5: function () {
      return localStorage.resultQuiz5 ? localStorage.resultQuiz5 : 0
    },
    getResQuiz6: function () {
      return localStorage.resultQuiz6 ? localStorage.resultQuiz6 : 0
    }
  }

}
</script>
